import { PropsWithChildren } from "react";
import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import { emotionCache } from "shared/utils/emotionCache/emotionCache";
import { Color } from "src/application/styles";
import { montserrat } from "./fonts";
import { addMantineColor } from "./utils";

export const defaultTheme: MantineThemeOverride = {
    colorScheme: "light",
    fontFamily: montserrat.style.fontFamily,
    colors: {
        main_Black: addMantineColor(Color.main_Black),
        main_MainDark: addMantineColor(Color.main_MainDark),
        main_MainDarkGray: addMantineColor(Color.main_MainDarkGray),
        main_MainLightGray: addMantineColor(Color.main_MainLightGray),

        second_SecondDarkGray: addMantineColor(Color.second_SecondDarkGray),
        second_SecondLightGray: addMantineColor(Color.second_SecondLightGray),
        second_white: addMantineColor(Color.second_white),

        validation_violet: addMantineColor(Color.validation_violet),
        validation_blue: addMantineColor(Color.validation_blue),
        validation_green: addMantineColor(Color.validation_green),
        validation_red: addMantineColor(Color.validation_red),
    },
    primaryColor: "main_Black",
    black: Color.main_Black,
    white: Color.second_white,
    components: {
        Stack: {
            defaultProps: {
                spacing: 0,
            },
        },
    },
    headings: {
        fontFamily: montserrat.style.fontFamily,
        fontWeight: montserrat.style.fontWeight,
    },
};

function ThemeProvider({ children }: PropsWithChildren) {
    return (
        <MantineProvider
            emotionCache={emotionCache}
            theme={defaultTheme}
            withGlobalStyles
            withNormalizeCSS
            withCSSVariables>
            {children}
        </MantineProvider>
    );
}

export default ThemeProvider;
